import axios from "axios";

const BASE_URL = "https://test-api.v-max.shop";

export const fetchData = async (endpoint, params = {}) => {
  try {
    const response = await axios.get(BASE_URL + endpoint, { params });
    return response.data;
  } catch (error) {
    // Handle error (you can also throw the error to be handled by the caller)
    console.error("API call error: ", error);
    return null;
  }
};
