export const salesTrendsPredicted = (salesTimeStamps, salesDataSeries) => {
  return {
    day: {
      xAxis: {
        name: "Hour",
        data: salesTimeStamps,
      },
      pieces: [
        {
          gt: -0.1,
          lte: 12,
          color: "#59C4E6",
        },
        {
          gt: 12,
          color: "#516B91",
        },
      ],
      series: {
        name: "Sales",
        data: salesDataSeries,
        markArea: {
          data: [
            [
              {
                name: "Actual Sales",
                xAxis: "0:00",
                itemStyle: {
                  color: "#A5E7F0",
                  opacity: 0.3,
                },
              },
              {
                xAxis: "12:00",
              },
            ],
            [
              {
                name: "Projected Sales",
                xAxis: "12:00",
                itemStyle: {
                  color: "#516B91",
                  opacity: 0,
                },
              },
              {
                xAxis: "23:00",
              },
            ],
          ],
        },
      },
    },
    week: {
      xAxis: {
        name: "Day",
        data: salesTimeStamps,
      },
      yAxis: {
        name: "Sales",
      },
      pieces: [
        {
          gt: -0.1,
          lte: 6,
          color: "#59C4E6",
        },
        {
          gt: 6,
          color: "#516B91",
        },
      ],
      series: {
        name: "Sales",
        data: salesDataSeries,
        markArea: {
          data: [
            [
              {
                name: "Actual Sales",
                xAxis: salesTimeStamps[0],
                itemStyle: {
                  color: "#A5E7F0",
                  opacity: 0.3,
                },
              },
              {
                xAxis: salesTimeStamps[6],
              },
            ],
            [
              {
                name: "Projected Sales",
                xAxis: salesTimeStamps[6],
                itemStyle: {
                  color: "#516B91",
                  opacity: 0,
                },
              },
              {
                xAxis: salesTimeStamps[salesTimeStamps.length - 1],
              },
            ],
          ],
        },
      },
    },
    month: {
      xAxis: {
        name: "Date",
        data: salesTimeStamps,
      },
      yAxis: {
        name: "Sales",
      },
      pieces: [
        {
          gt: -0.1,
          lte: 29,
          color: "#59C4E6",
        },
        {
          gt: 29,
          color: "#516B91",
        },
      ],
      series: {
        name: "Sales",
        data: salesDataSeries,
        markArea: {
          data: [
            [
              {
                name: "Actual Sales",
                xAxis: salesTimeStamps[0],
                itemStyle: {
                  color: "#A5E7F0",
                  opacity: 0.3,
                },
              },
              {
                xAxis: salesTimeStamps[29],
              },
            ],
            [
              {
                name: "Projected Sales",
                xAxis: salesTimeStamps[29],
                itemStyle: {
                  color: "#516B91",
                  opacity: 0,
                },
              },
              {
                xAxis: salesTimeStamps[salesTimeStamps.length - 1],
              },
            ],
          ],
        },
      },
    },
  };
};

export const orderTrendsPredicted = (ordersTimeStamp, ordersDataSeries) => {
  return {
    day: {
      xAxis: {
        name: "Hour",
        data: ordersTimeStamp,
      },
      pieces: [
        {
          gt: -0.1,
          lte: 12,
          color: "#59C4E6",
        },
        {
          gt: 12,
          color: "#516B91",
        },
      ],
      series: {
        name: "Orders",
        data: ordersDataSeries,
        markArea: {
          data: [
            [
              {
                name: "Actual Orders",
                xAxis: "0:00",
                itemStyle: {
                  color: "#A5E7F0",
                  opacity: 0.3,
                },
              },
              {
                xAxis: "12:00",
              },
            ],
            [
              {
                name: "Projected Orders",
                xAxis: "12:00",
                itemStyle: {
                  color: "#516B91",
                  opacity: 0,
                },
              },
              {
                xAxis: "23:00",
              },
            ],
          ],
        },
      },
    },
    week: {
      xAxis: {
        name: "Day",
        data: ordersTimeStamp,
      },
      yAxis: {
        name: "Orders",
      },
      pieces: [
        {
          gt: -0.1,
          lte: 6,
          color: "#59C4E6",
        },
        {
          gt: 6,
          color: "#516B91",
        },
      ],
      series: {
        name: "Orders",
        data: ordersDataSeries,
        markArea: {
          data: [
            [
              {
                name: "Actual Orders",
                xAxis: ordersTimeStamp[0],
                itemStyle: {
                  color: "#A5E7F0",
                  opacity: 0.3,
                },
              },
              {
                xAxis: ordersTimeStamp[6],
              },
            ],
            [
              {
                name: "Projected Orders",
                xAxis: ordersTimeStamp[6],
                itemStyle: {
                  color: "#516B91",
                  opacity: 0,
                },
              },
              {
                xAxis: ordersTimeStamp[ordersTimeStamp.length - 1],
              },
            ],
          ],
        },
      },
    },
    month: {
      xAxis: {
        name: "Date",
        data: ordersTimeStamp,
      },
      yAxis: {
        name: "Orders",
      },
      pieces: [
        {
          gt: -0.1,
          lte: 29,
          color: "#59C4E6",
        },
        {
          gt: 29,
          color: "#516B91",
        },
      ],
      series: {
        name: "Orders",
        data: ordersDataSeries,
        markArea: {
          data: [
            [
              {
                name: "Actual Orders",
                xAxis: ordersTimeStamp[0],
                itemStyle: {
                  color: "#A5E7F0",
                  opacity: 0.3,
                },
              },
              {
                xAxis: ordersTimeStamp[29],
              },
            ],
            [
              {
                name: "Projected Orders",
                xAxis: ordersTimeStamp[29],
                itemStyle: {
                  color: "#516B91",
                  opacity: 0,
                },
              },
              {
                xAxis: ordersTimeStamp[ordersTimeStamp.length - 1],
              },
            ],
          ],
        },
      },
    },
  };
};

export const locationData = (locationCategories, locationRates) => {
  return {
    xAxis: {
      name: "Location",
      data: locationCategories,
    },
    series: {
      data: locationRates.map((rate) => rate * 100),
    },
  };
};

export const genderData = (genderCategories, genderRates) => {
  return {
    data: genderCategories.map((category, index) => {
      return { value: genderRates[index] * 100, name: category };
    }),
  };
};
