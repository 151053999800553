import React, { useEffect, useState } from "react";
import "./VisitResultsCard.scss";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LineChart from "../../../ui-components/Charts/LineChart";
import { fetchData } from "../../../api";
import { useSelector } from "react-redux";
import { visitResults } from "./VisitResultsCard.constants";

export default function VisitResultsCard() {
  const user = useSelector((state) => state.user);
  const [period, setPeriod] = useState("week");

  const yesterdayHours = Array.from({ length: 24 }, (_, hour) => `${hour}:00`);
  const [visitsYesterday, setVisitsYesterday] = useState([]);

  const [lastWeekDays, setLastWeekDays] = useState([]);
  const [visitsLastWeek, setVisitsLastWeek] = useState([]);

  const [lastMonthDays, setLastMonthDays] = useState([]);
  const [visitsLastMonth, setVisitsLastMonth] = useState([]);

  const loadVisitResData = async () => {
    const visitRes = await fetchData("/visit_result", {
      brand_id: user.brandId,
      time_period: period,
    });
    if (visitRes) {
      switch (period) {
        case "day":
          setVisitsYesterday(visitRes.data["data_series"]);
          break;
        case "week":
          setLastWeekDays(visitRes.data["time_stamps"]);
          setVisitsLastWeek(visitRes.data["data_series"]);
          break;
        case "month":
          setLastMonthDays(visitRes.data["time_stamps"]);
          setVisitsLastMonth(visitRes.data["data_series"]);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    user && loadVisitResData();
  }, [user, period]);

  const handleChange = (event) => {
    setPeriod(event.target.value);
  };

  return (
    <div className="visit-results-container">
      <div className="visit-title-wrapper">
        <p className="visit-title">Visit Results</p>
        <div>
          <span>Time Period</span>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={period}
            onChange={handleChange}
            className="visit-period-select"
          >
            <MenuItem value="day">Yesterday</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
          </Select>
        </div>
      </div>
      <LineChart
        data={visitResults(
          yesterdayHours,
          visitsYesterday,
          lastWeekDays,
          visitsLastWeek,
          lastMonthDays,
          visitsLastMonth
        )}
        chosenTime={period}
      />
    </div>
  );
}
