import React, { useEffect } from "react";
import "./SumNumCard.scss";
import Counter from "../../ui-components/Counter";
export default function SumNumCard({ sumNumItem, summaryNums }) {
  return (
    <div className="sum-num-card-container">
      <div>
        <p className="sum-num-title">{sumNumItem.title}</p>
        <div className="sum-num-wrapper">
          <span className="sum-num-data">
            {summaryNums && (
              <Counter
                to={
                  (sumNumItem.dataKey !== "conversion_rate"
                    ? summaryNums[sumNumItem.dataKey]
                    : 0.0045) * (sumNumItem.unit !== "%" ? 1 : 100)
                }
                toFixedVal={sumNumItem.toFixedVal ? sumNumItem.toFixedVal : 0}
              />
            )}
          </span>
          <span className="sum-num-unit">
            {sumNumItem.unit && sumNumItem.unit}
          </span>
        </div>
      </div>
      <div
        className="sum-num-icon-container"
        style={{ backgroundColor: sumNumItem.iconColor }}
      >
        <img src={sumNumItem.iconSrc} />
      </div>
    </div>
  );
}
