import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./PlatformCard.scss";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LineChartPredicted from "../../../ui-components/Charts/LineChartPredicted";
import BarChart from "../../../ui-components/Charts/BarChart";
import PieChart from "../../../ui-components/Charts/PieChart";
import {
  salesTrendsPredicted,
  orderTrendsPredicted,
  locationData,
  genderData,
} from "./PlatformCard.constants";
import { fetchData } from "../../../api";

export default function PlatformCard() {
  const user = useSelector((state) => state.user);
  const initialPeriod = "week";
  const yesterdayHours = Array.from({ length: 24 }, (_, hour) => `${hour}:00`);

  const [platform, setPlatform] = useState("yt");
  const [source, setSource] = useState("video");
  const [period, setPeriod] = useState(initialPeriod);
  const [borderColors, setBorderColors] = useState({
    yt: "#FF0000",
    fb: "#bebebe",
    ig: "#bebebe",
    tt: "#bebebe",
  });

  // state data related to sales
  const [salesTimeStamps, setSalesTimeStamps] = useState([]);
  const [salesDataSeries, setSalesDataSeries] = useState([]);

  // state data related to order
  const [ordersTimeStamp, setOrdersTimeStamp] = useState([]);
  const [ordersDataSeries, setOrdersDataSeries] = useState([]);

  // state data related to location
  const [locationCategories, setLocationCategories] = useState([]);
  const [locationRates, setLocationRates] = useState([]);

  // state data related to location
  const [genderCategories, setGenderCategories] = useState([]);
  const [genderRates, setGenderRates] = useState([]);

  // state data related to video views
  const [viewRankData, setViewRankData] = useState([]);

  // state data related to product sales
  const [productRankData, setProductRankData] = useState([]);

  const highlightColor = (key, color) => {
    setPlatform(key);
    setBorderColors({
      yt: "#bebebe",
      fb: "#bebebe",
      ig: "#bebebe",
      tt: "#bebebe",
      [key]: color,
    });
  };

  const periodHandleChange = (event) => {
    setPeriod(event.target.value);
  };

  const sourceHandleChange = (event) => {
    setSource(event.target.value);
  };

  const getPlatformName = (platformId) => {
    switch (platformId) {
      case "yt":
        return "youtube";
      case "fb":
        return "facebook";
      case "ig":
        return "instagram";
      case "tt":
        return "tiktok";
      default:
        return;
    }
  };

  const loadSalesResData = async () => {
    const salesRes = await fetchData("/platform_sale_results", {
      brand_id: user.brandId,
      platform: getPlatformName(platform),
      time_period: period,
    });
    if (salesRes) {
      switch (period) {
        case "day":
          setSalesTimeStamps(yesterdayHours);
          setSalesDataSeries(salesRes.data["data_series"]);
          break;
        default:
          setSalesTimeStamps(salesRes.data["time_stamps"]);
          setSalesDataSeries(salesRes.data["data_series"]);
          break;
      }
    }
  };

  const loadOrderResData = async () => {
    const orderRes = await fetchData("/platform_order_results", {
      brand_id: user.brandId,
      platform: getPlatformName(platform),
      time_period: period,
    });
    if (orderRes) {
      switch (period) {
        case "day":
          setOrdersTimeStamp(yesterdayHours);
          setOrdersDataSeries(orderRes.data["data_series"]);
          break;
        default:
          setOrdersTimeStamp(orderRes.data["time_stamps"]);
          setOrdersDataSeries(orderRes.data["data_series"]);
          break;
      }
    }
  };

  const loadLocationRes = async () => {
    const locationRes = await fetchData("/platform_viewer_location", {
      brand_id: user.brandId,
      platform: getPlatformName(platform),
      time_period: period,
    });
    if (locationRes) {
      const jsonData = JSON.parse(locationRes.data["data_series"])[0];
      setLocationCategories(jsonData["countries"]);
      setLocationRates(jsonData["rates"]);
    }
  };

  const loadGenderRes = async () => {
    const genderRes = await fetchData("/platform_viewer_gender", {
      brand_id: user.brandId,
      platform: getPlatformName(platform),
      time_period: period,
    });
    if (genderRes) {
      const jsonData = JSON.parse(genderRes.data["data_series"])[0];
      setGenderCategories(jsonData["gender"]);
      setGenderRates(jsonData["rates"]);
    }
  };

  const loadViewRankRes = async () => {
    const viewRankRes = await fetchData("/video_ranking", {
      brand_id: user.brandId,
      platform: getPlatformName(platform),
      time_period: period,
    });
    if (viewRankRes) {
      setViewRankData(viewRankRes.data["data_series"]);
    }
  };

  const loadProductRankRes = async () => {
    const productRankRes = await fetchData("/product_ranking", {
      brand_id: user.brandId,
      platform: getPlatformName(platform),
      time_period: period,
    });
    if (productRankRes) {
      setProductRankData(productRankRes.data["data_series"]);
    }
  };

  useEffect(() => {
    user && loadSalesResData();
    user && loadOrderResData();
    user && loadLocationRes();
    user && loadViewRankRes();
    user && loadProductRankRes();
    user && loadGenderRes();
  }, [platform, period, user]);

  return (
    <>
      <div className="platform-nav-container">
        <h2
          style={{ borderBottom: `solid 4px ${borderColors["yt"]}` }}
          onClick={() => highlightColor("yt", "#FF0000")}
        >
          Youtube
        </h2>
        <h2
          style={{ borderBottom: `solid 4px ${borderColors["fb"]}` }}
          onClick={() => highlightColor("fb", "#2F80ED")}
        >
          Facebook
        </h2>
        <h2
          style={{ borderBottom: `solid 4px ${borderColors["ig"]}` }}
          onClick={() => highlightColor("ig", "#FF00B8")}
        >
          Instagram
        </h2>
        <h2
          style={{ borderBottom: `solid 4px ${borderColors["tt"]}` }}
          onClick={() => highlightColor("tt", "#000000")}
        >
          TikTok
        </h2>
      </div>

      <div className="platform-selectors-container">
        <div>
          <span>Time Period</span>
          <Select
            value={period}
            onChange={periodHandleChange}
            className="platform-period-select"
          >
            <MenuItem value="day">Yesterday</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
          </Select>
        </div>
        {/* <div className="source-select-container">
          <span>Source</span>
          <Select
            value={source}
            onChange={sourceHandleChange}
            className="platform-period-select"
          >
            <MenuItem value="video">Video Organic</MenuItem>
            <MenuItem value="ad">Advertising</MenuItem>
            <MenuItem value="live">Live Stream</MenuItem>
          </Select>
        </div> */}
      </div>
      <div className="platform-detail-cards-container">
        <div className="platform-detail-card">
          <span>Sales Amount & Trend</span>
          <LineChartPredicted
            data={
              salesTrendsPredicted(salesTimeStamps, salesDataSeries)[period]
            }
            unit="$"
          />
        </div>
        <div className="platform-detail-card">
          <span>Order Amount & Trend</span>
          <LineChartPredicted
            data={
              orderTrendsPredicted(ordersTimeStamp, ordersDataSeries)[period]
            }
          />
        </div>
        <div className="platform-detail-card">
          <span>Location</span>
          <BarChart data={locationData(locationCategories, locationRates)} />
        </div>
        <div className="platform-detail-card">
          <span>Gender</span>
          <PieChart data={genderData(genderCategories, genderRates)} />
        </div>
        <div className="platform-detail-card">
          <span>Ranking by video views</span>
          <ul className="video-list-container">
            <li>
              <div>Video Name</div>
              <div>Views</div>
              <div>Sales</div>
              <div></div>
            </li>
            {viewRankData.map((videoItem) => (
              <li>
                <div>
                  <div>
                    <img src={videoItem["video_thumbnail"]} />
                  </div>
                  <p>{videoItem["video_name"]}</p>
                </div>
                <div>
                  <p>{videoItem["video_views"]}</p>
                </div>
                <div>
                  <p>{videoItem["video_sales"]}</p>
                </div>
                <div>
                  <a
                    href={videoItem["video_link"]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button>Open Link</button>
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="platform-detail-card">
          <span>Ranking by sales</span>
          <ul className="product-list-container">
            <li>
              <div>Product Name</div>
              <div>CTR</div>
              <div>Views</div>
              <div>Sales</div>
            </li>
            {productRankData.map((listItem) => (
              <li>
                <div>
                  <div>
                    <img src={listItem["product_thumbnail"]} />
                  </div>
                  <p>{listItem["product_name"]}</p>
                </div>
                <div>
                  <p>{listItem["click_through_rate"]}</p>
                </div>
                <div>
                  <p>{listItem["video_views"]}</p>
                </div>
                <div>
                  <p>{listItem["product_sales"]}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
