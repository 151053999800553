import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home/Home";
import Profile from "./pages/Profile/Profile";
import Navbar from "./ui-components/Navbar/Navbar";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { WithAuthenticator } from "./authenticator/WithAuthenticator";

const App = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
  });

  return (
    <Provider store={store}>
      <WithAuthenticator>
        <Router className="App">
          <Navbar screenWidth={screenWidth} />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/profile" exact element={<Profile />} />
          </Routes>
        </Router>
      </WithAuthenticator>
    </Provider>
  );
};

export default App;
