export const visitResults = (
  yesterdayHours,
  visitsYesterday,
  lastWeekDays,
  visitsLastWeek,
  lastMonthDays,
  visitsLastMonth
) => {
  return {
    day: {
      xAxis: {
        name: "Hour",
        data: yesterdayHours,
      },
      series: {
        name: "Visits",
        data: visitsYesterday,
      },
    },
    week: {
      xAxis: {
        name: "Day",
        data: lastWeekDays,
      },
      series: {
        name: "Visits",
        data: visitsLastWeek,
      },
    },
    month: {
      xAxis: {
        name: "Date",
        data: lastMonthDays,
      },
      series: {
        name: "Visits",
        data: visitsLastMonth,
      },
    },
  };
};
