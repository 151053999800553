import React from "react";
import "./Profile.scss";

export default function Profile() {
  return (
    <div className="page">
      <div className="bg-black"></div>
      <div className="bg-gradient"></div>
      <div className="main-content">
        <h2 className="brand-name-title">Brand name 01</h2>
        <div className="card accounts-card"></div>
      </div>
    </div>
  );
}
